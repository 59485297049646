import { Box, Select, Typography, selectClasses, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';

export const DynamicWidthDataGrid = styled(DataGrid)<{ width: number }>(
  ({ theme, width }) => ({
    alignSelf: 'stretch',
    [theme.breakpoints.only('desktop')]: {
      '.MuiDataGrid-main': {
        alignSelf: width > 0 ? 'center' : 'stretch',
        width,
      },
    },
  })
);

export const SmallSelect = styled(Select)(({ theme }) => ({
  alignSelf: 'stretch',
  padding: theme.spacing(1, 0),
  fontSize: '0.75rem',
  [`& .${selectClasses.select}`]: {
    padding: theme.spacing(0, 1),
  },
}));

export const PerPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const TitleContainer = styled(Typography)(() => ({
  color: grey[600],
  textTransform: 'uppercase',
  whiteSpace: 'pre-line',
  width: 70,
}));
