import { QuickFilters } from '@components/molecules';
import QuickFiltersSkeleton from '@components/molecules/portfolio/QuickFilters/QuickFilter.loading';
import { Box, styled, Theme } from '@mui/material';

export const SitesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(2)};
  }
`;
export const CenteredContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    width: 668,
    flex: 1,
    alignSelf: 'center',
  },
}));

export const QuickFilterCentered = styled(QuickFilters)(() => ({}));

export const QuickFilterCenteredLoading = styled(QuickFiltersSkeleton)(
  ({ theme }) => ({
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'center',
    },
  })
);

export const SearchingLogo = styled('img')(({ theme }) => ({
  padding: theme.spacing(0, 1.4),
}));
