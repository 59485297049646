import React, { useCallback, useState } from 'react';
import { GradientSection } from '@components/templates/layouts/containers';
import { PaperColor, PaperPage } from '@components/templates/layouts/papers';
import { useTranslation } from 'react-i18next';
import { TopSection, RiskGrade, InfoDetails } from '@components/molecules';
import { useSite, useSiteFootprint } from '@modules/services/services-sites';
import { useParams, useSearchParams } from 'react-router-dom';
import { PortfolioRiskValue, SiteLinks } from '@components/organisms';
import { TierColumns } from '@components/templates';
import { getRiskGrade } from '../../utils';
import IndividualSiteSkeleton from './IndividualSite.loading';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import {
  useCoach,
  useCompany,
  useSendMessageContact,
} from '@modules/services/services-company';
import { RiskMessage } from '@modules/types';
import { TooltipMapMessage } from '@components/atoms/TooltipMapMessage';
import { AlertType } from '@components/atoms/TooltipMapMessage/TooltipMapMessage.types';
import { TooltipPositionSiteDetail } from '@components/atoms/TooltipMapMessage/TooltipMapMessage.styles';
import { ModalRisksTutorial } from '@components/organisms/portfolio/ModalRisksTutorial';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { data: company } = useCompany();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const [isOpenTuto, setIsOpenTuto] = useState<boolean>(false);
  const [alertStatus, setAlertStatus] = useState<AlertType>();

  const displayAlert = (status: AlertType) => {
    setDisplayToast(true);
    setAlertStatus(status);
    setTimeout(() => setDisplayToast(false), 5000);
  };

  const { mutate, isLoading } = useSendMessageContact();

  const postMessage = (message: RiskMessage) =>
    mutate(message, {
      onSuccess: () => displayAlert(AlertType.SUCCESS),
      onError: () => displayAlert(AlertType.ERROR),
    });

  const closeTutorial = useCallback(
    () => setIsOpenTuto(false),
    [setIsOpenTuto]
  );

  const openTutorial = useCallback(() => setIsOpenTuto(true), [setIsOpenTuto]);

  const { data: coach } = useCoach();

  const { data: site } = useSite(params.id);
  const path = searchParams.get('path');

  const transformSiteInsuredValue = {
    ...site?.insuredValues,
    total: site?.insuredValues.site || 0,
  };
  const { data: siteFootprint } = useSiteFootprint(params.id);

  const riskGrade = getRiskGrade(site?.riskGrade);

  if (!(site && siteFootprint)) {
    return <IndividualSiteSkeleton />;
  }

  return (
    <>
      <ModalRisksTutorial
        isOpen={isOpenTuto}
        closeText={t('action.close')}
        onClose={closeTutorial}
      />
      {displayToast && (
        <TooltipPositionSiteDetail>
          <TooltipMapMessage
            text={t(`site.wrong-data-message.${alertStatus}`)}
            type={alertStatus}
          />
        </TooltipPositionSiteDetail>
      )}
      <GradientSection color="ocean">
        <TopSection
          back
          backText={
            path === 'map' ? t('navigation.map') : t('navigation.sites')
          }
          href={`/portfolio${path ? '/' + path : '?sort=summary'}`}
          title={site.name}
        />
      </GradientSection>
      <TierColumns>
        {riskGrade && (
          <PaperColor maxwidth={324} bottomcolor={riskGrade.color}>
            <RiskGrade
              {...riskGrade}
              titleRisk={t('site.risk.grade')}
              titleTooltip={t('site.risk.tooltip.title')}
              descriptionTooltip={t('site.risk.tooltip.description')}
            />
          </PaperColor>
        )}
        <PaperPage>
          <PortfolioRiskValue
            riskTooltip={{
              title: t('site.risk.total.title'),
              description: t('site.risk.total.description'),
            }}
            valueTitle={t('site.value.title')}
            valueDescription={t('site.value.description')}
            titleValueDonut={t('portfolio.value.tiv')}
            companyCurrency={company?.currencyIso3}
            siteValueDescription={t('portfolio.value.site-value')}
            sourceText={t('footer.risk-attributes')}
            displayExplanation={true}
            wrongValueText={t('site.value.wrongValue') || ''}
            redirectionLink={t('site.value.wrongValueRedirection') || ''}
            onSendMessage={postMessage}
            openModal={openTutorial}
            description={t('portfolio.risk.tooltip.description')}
            redirectionText={t('portfolio.tutorial.open_tutorial')}
            errorMessage={
              t('site.value.mail-container', {
                locNum: site.locNum,
              }) || ''
            }
            coachID={coach?.id}
            {...site.perilScoreSummary}
            {...transformSiteInsuredValue}
          />
        </PaperPage>
        <PaperPage column={1} sx={{ flex: 1 }}>
          <SiteLinks
            name={site.name}
            company={company?.description}
            footprint={siteFootprint.footprint}
          />
        </PaperPage>
        <PaperPage>
          {!!site && (
            <InfoDetails
              identifier={site.locNum}
              occupancy={site.stockTypeSite}
              address={site.address || site.addressSite}
              location={site.name}
              dataQuality={t('site.fields.data-quality')}
              fieldAddress={t('site.fields.address')}
              fieldId={t('site.fields.id')}
              fieldName={t('site.fields.name')}
              fieldOccupancy={t('site.fields.occupancy')}
              fieldLocation={t('site.fields.location')}
              fieldResolution={t('site.fields.resolution-value')}
              fieldConfidence={t('site.fields.confidence')}
              fieldSource={t('site.fields.source')}
              confidence={site.confidence}
              resolution={site.resolution}
              source={site.source}
              wrongAdressTitle={t('site.fields.wrong-address') || ''}
              coachID={coach?.id}
              onSendMessage={postMessage}
              isLoading={isLoading}
              errorMessage={
                t('site.value.mail-container', {
                  locNum: site.locNum,
                }) || ''
              }
            />
          )}
        </PaperPage>
      </TierColumns>
    </>
  );
};

export default withErrorBoundary(Screen);
